import React from 'react';
import { Link } from 'react-router-dom'
// import { Button } from './Button';

function PriceCardItem(props) {
  return (
    <>
      <li className='pricecards__item'>
        <Link className='cards__item__link' to={props.path}>
          <div className='cards__item__info'>
            <h5 className='cards__item__text'>{props.text}</h5>
          </div>
          <div className='cards__item__info'>
            <h5 className='cards__item__detail'>{props.price}</h5>
          </div>
          <div className='cards__item__info'>
            <h5 className='cards__item__detail'>{props.price2}</h5>
          </div>
        </Link>
      </li>
    </>
  );
}

export default PriceCardItem;
