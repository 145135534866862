import React from 'react';
import './pages.css';

export default function Phototherapy() {
    return (
        <>
            <div className="header">
                <h1>What is Phototherapy?</h1>
            </div>
            <div className='leftcolumn'>
                <div classname='card'>
                    <p>Photizo’s Vetcare LED light therapy harnesses the healing power of red and infrared light.These are at specific wavelengths and frequencies that have been proven to aid  accelerated healing by activating the pain and relaxing ‘medication’ of an animal’s own body.</p>
                    <br></br>
                    <p>Photizo Vetcare is non-invasive, painless, produces a gentle and soothing warmth and does not require any recovery time after treatment. The light therapy allows increased blood flow into the area of the body being treated to ensure that any waste is quickly removed, and healing can take place.</p>
                    <br></br>
                    <div className='highlight'>
                        <p>Phototherapy is perfect for treating conditions such as:</p>
                        <p> Skin conditions: wounds, lacerations, saddle sores, skin allergies, eczema</p>
                        <p> Musculoskeletal issues: arthritis, tendonitis, overuse injuries and muscle spasms</p>
                        <p> Post- Op recovery: any area treated surgically, including skin grafts.</p>
                        <p> Corneal ulcers, viral related conditions and sinusitis.</p>
                    </div>
                </div>
                <div clasname='rightcolumn'>
                    <div classname='card'>
                        <img
                            src='./images/phototherapy.jpg'
                            width={200} height={200}
                            alt='' />
                        <img
                            src='./images/photolaser.png'
                            width={200} height={200}
                            alt='' />
                    </div>
                </div>
            </div>
        </>
    );
}