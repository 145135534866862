import React from 'react';
import Navbar from './components/Navbar';
// import './App.css';
import {BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './components/pages/Home';
import Reiki from './components/pages/Reiki';
import Therapies from './components/pages/Therapies';
import Pricing from './components/pages/Pricing';
import AboutMe from './components/pages/AboutMe';
import MerishaMassage from './components/pages/Merishia';
import Phototherapy from './components/pages/Phototherapy';

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Switch>
        <Route path='/' exact component={Home} />
        <Route path='/reiki' component={Reiki} />
        <Route path='/merishia' component={MerishaMassage} />
        <Route path='/about' component={AboutMe} />
        <Route path='/phototherapy' component={Phototherapy} />
        <Route path='/therapies' component={Therapies} />
        <Route path='/pricing' component={Pricing} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
