import React from 'react';
import './pages.css';

export default function Reiki() {
    return (
        <>
            <div className="header">
                <h1>What is Animal Led Reiki?</h1>
            </div>
            <div className='leftcolumn'>
                <div classname='card'>
                    <p>Animal Reiki is a spiritual practice of compassion that uses Reiki's healing energy to help animals rebalance on physical, mental, emotional and energetic levels. </p>
                    <p>I follow the Let Animals Lead® method of animal Reiki which is guided by each individual animal and is not always hands on. </p>
                    <br></br>
                    <p>Reiki is safe and will never harm an animal. If an animal has experienced abuse or neglect, Reiki helps it to release emotional wounds. Treating horses and pets with Reiki helps relax and calm them, making it easier for owners and trainers to handle them and resolve various issues. A calmer animal means a calmer, happier owner.</p>
                    <p>Reiki is extremely useful in conjunction with traditional veterinary care. It helps animals recover faster, saving owners anxiety and cost. Veterinarians trained in this type of healing have found it useful in treating travel stress and digestive problems, as well as settling mares who are new mothers.</p>
                    <br></br>
                    <div className='highlight'>
                        <p>Reiki is:</p>
                        <p> Safe</p>
                        <p> Relaxing</p>
                        <p> Natural</p>
                        <p> Balancing</p>
                        <br></br>
                        <p>Reiki may help with: </p>
                        <p> Alleviating pain and discomfort</p>
                        <p> Recovery from injury or illness</p>
                        <p> Reducing stress, anxiety, depression and fear</p>
                        <p> Reducing aggression</p>
                        <p> Promoting relaxation</p>
                        <p> Behavioural issues</p>
                        </div>
                </div>
                <div clasname='rightcolumn'>
                    <div classname='card'>
                        <img
                            src='./images/reikidog.png'
                            width={200} height={200}
                            alt='' />
                        <img
                            src='./images/reikihorse.png'
                            width={200} height={200}
                            alt='' />
                    </div>
                    </div>
            </div>
        </>
    );
}