import React from 'react';
import '../../App.css';
import PriceCards from '../PriceCards';
import Header from '../Header'
import Footer from '../Footer';
import Terms from '../Terms'

export default function Pricing() {
  return (
    <>
      <Header />
      <PriceCards />
      <Terms />
      <Footer />
    </>
  );
}
