import React from 'react';
import '../../App.css';
import Cards from '../Cards';
import HeroSection from '../HeroSection';
import Header from '../Header'
import Footer from '../Footer';

export default function Home() {
  return (
    <>
      <Header />
      <HeroSection />
      <Cards />
      <Footer />
    </>
  );
}
