import React from 'react';
import '../../App.css';
import Phototherapy from '../Phototherapy';
import Header from '../Header'
import Footer from '../Footer';
import Testimonials from '../Testimonials';

export default function PhototherapyPage() {
  return (
    <>
      <Header />
      <Phototherapy />
      <Testimonials />
      <Footer />
    </>
  );
}
