import React from 'react'
import Carousel from 'better-react-carousel'
import './pages.css'; 

export default function Testimonials() {
  return (
    <div className='carousel'>
    <Carousel cols={1} rows={1} gap={2} loop autoplay={5000} showDots>
      <Carousel.Item>
        <p>Treacle absolutely loves her reiki sessions. She always seems that little bit more relaxed and happier after each session, as if she has made any of her problems known and a weight has been lifted from her. She enjoys every moment in the sessions and is very comfortable with Tracy being in her space</p>
        <p> <br></br></p>
        <h3 className='carousel-name'>Steph</h3>
      </Carousel.Item>
      <Carousel.Item>
        <p>Tracy met my mare who was very stressed with moving yards. Tracy did some reiki on her and helped me get her settled by doing what my horse needed and asked for. It was very helpful and welcomed as we really needed the help. The reiki made a visible difference. Thank you</p>
        <p> <br></br></p>
        <h3 className='carousel-name'>Gina</h3>
      </Carousel.Item>
      {/* <Carousel.Item>
        <h3>Third slide label</h3>
        <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
      </Carousel.Item> */}
      {/* ... */}
    </Carousel>
    </div>

  )
}