import React from 'react';
import './pages.css';

export default function Terms() {
  return (
      <div className="header">
        <h2>Booking Terms and Conditions:</h2>
        <div className='detail'>
          <div>
          <p>1.Treatment sessions are approximately 60-90 minutes but may vary according to how much the Animal can process in one session</p>
          <p>This does not apply to Phototherapy sessions which last between 20-30 mins.</p>
          <p>2.Payment required on day of session by Cash/bank transfer</p>
          <p>3.Cancellations within 24 hours of a booking may incur a cancellation fee</p>
          <p>4.Travel charges apply for distances over 20 miles</p>
          </div>
        </div>
      <div className="header">
        <h2>Travel Costs:</h2>
        <div className="detail">
          <p>Free up to 20 miles of TN27, 45p a mile thereafter each way.</p>
        </div>
        </div>
      </div>
  )
}