import React from 'react';
import './Cards.css';
import PriceCardItem from './PriceCardItem';

function Cards() {
  return (
    <div className='hero-container'>
      <div className='pricecards'>
        <div className='pricecards'>
          <div className='cards__container'>
            <div className='cards__wrapper'>
              <ul className='cards__items'>
                <PriceCardItem
                  // src='images/merishia-massage.jpg'
                  text='Equine Merishia Massage'
                  price='1st Session £45'
                  price2='Follow up sessions £40'
                  path='/merishia'
                />
                <PriceCardItem
                  // src='images/merishia-massage.jpg'
                  text='Canine Merishia Massage'
                  price='1st Session £40'
                  price2='Follow up sessions £35'
                  path='/merishia'
                />
                <PriceCardItem
                  src='images/reiki_healing.jpg'
                  text='Animal Led Reiki'
                  price='1-2-1 Session £35'
                  price2='Distance Reiki £25'
                  path='/reiki'
                />
                <PriceCardItem
                  src='images/phototherapy.jpg'
                  text='Phototherapy'
                  price='Equine £25'
                  price2='Canine £20'
                  path='/phototherapy'
                />
              </ul>
            </div>
          </div>
        </div>
      </div>
   </div>
  );
}

export default Cards;
