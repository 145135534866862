import React from 'react';
import '../../App.css';
import Merishia from '../MerishiaMassage';
import Header from '../Header'
import Footer from '../Footer';
import Testimonials from '../Testimonials';

export default function MerishiaMassagePage() {
  return (
    <>
      <Header />
      <Merishia />
      <Testimonials />
      <Footer />
    </>
  );
}
