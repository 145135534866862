import React from 'react'
import Carousel from 'better-react-carousel'

export default function Gallery() {
    return (
        <Carousel cols={4} rows={1} gap={2} height={"400px"} loop autoplay={5000} showDots> 
            <Carousel.Item>
                <img width="100%" height="400px" src="./images/profile.jpg" alt='' />
            </Carousel.Item>
            <Carousel.Item>
                <img width="100%" height="400px" src="./images/raven.jpg" alt=''/>
            </Carousel.Item>
            <Carousel.Item>
                <img width="100%" height="400px" src="./images/boxer.jpg" alt=''/>
            </Carousel.Item>
            <Carousel.Item>
                <img width="100%" height="400px" src="./images/esme.jpg" alt='' />
            </Carousel.Item>
            <Carousel.Item>
                <img width="100%" height="400px" src="./images/melody.jpg" alt='' />
            </Carousel.Item>
            <Carousel.Item>
                <img width="100%" height="400px" src="./images/ponies.jpg" alt='' />
            </Carousel.Item>
            <Carousel.Item>
                <img width="100%" height="400px" src="./images/puppies.jpg" alt='' />
            </Carousel.Item>
            <Carousel.Item>
                <img width="100%" height="400px" src="./images/curious.jpg" alt='' />
            </Carousel.Item>
            {/* ... */}
        </Carousel>
    )
}