import React from 'react';
import '../App.css';
// import './Header.css';
import Logo from '../assets/images/logo2.png'
import { Link } from 'react-router-dom';

function Header() {
    return (
        <>
        <div className='hero-box'>
            <Link
              classname='nav-link'
              to='/' 
              >
            <img 
              style={{ width: 300, height: 300 }} 
              src={Logo} 
              alt="Equinine Animal Therapy Logo" />
            </Link>
            </div>

        </>
    )
}

export default Header
