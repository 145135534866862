import React from 'react';
import '../../App.css';
import AboutMe from '../AboutMe';
import Header from '../Header'
import Footer from '../Footer';
import Gallery from '../Carousel';
import Testimonials from '../Testimonials';

export default function ReikiPage() {
  return (
    <>
      <Header />
      <Gallery />
      <AboutMe />
      <Testimonials />
      <Footer />
    </>
  );
}