import React from 'react';
import Reiki from '../Reiki';
import Header from '../Header'
import Footer from '../Footer';
import Testimonials from '../Testimonials';

export default function ReikiPage() {
  return (
    <>
      <Header />
      <Reiki />
      <Testimonials />
      <Footer />
    </>
  );
}
