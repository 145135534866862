import React from 'react';
import '../../App.css';
import Cards from '../Cards';
import Header from '../Header'
import Footer from '../Footer';
import Testimonials from '../Testimonials';


export default function Therapies() {
  return (
    <>
      <Header />
      <Cards />
      <Testimonials />
      <Footer />
    </>
  );
}
