import React from 'react';
import './Footer.css';
// import { Button } from './Button';
import { Link } from 'react-router-dom';
import rhalogo from '../assets/images/RHA-Logo-2.png';


function Footer() {
  return (
    <div className='footer-container'>
      <section className='social-media'>
        <div className='social-media-wrap'>
          <small className='website-rights'>Equinine Animal Therapy © 2019</small>
          <div className='social-icons'>
            <Link
              className='social-icon-link facebook'
              to={{ pathname: "https://www.facebook.com/equinineanimaltherapy" }}
              target='_blank'
              aria-label='Facebook'
            >
              <i className='fab fa-facebook-f' />
            </Link>
            <Link
              className='social-icon-link instagram'
              to={{ pathname: "https://www.instagram.com/equinineanimaltherapy/" }}
              target='_blank'
              aria-label='Instagram'
            >
              <i className='fab fa-instagram' />
            </Link>
            <Link
              className='social-icon-link twitter'
              to={{ pathname: "https://twitter.com/equininetherapy" }}
              target='_blank'
              aria-label='Twitter'
            >
              <i className='fab fa-twitter' />
            </Link>
          </div>
          <div>
            <Link
              className='nav-link'
              to={{ pathname: "https://reikihealingassociation.com/directory/animal-led-reiki/" }}
              target='_blank'
              aria-label='Reiki Healing Association'
            >
              <img src={rhalogo} className='nav-link' alt='rha' />
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
