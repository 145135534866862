import React from 'react';
import './pages.css';
// import RHA from '../assets/images/RHA-Logo-2.png'
// import profile from '../assets/images/profile.jpg'
// import { Link } from 'react-router-dom';


export default function AboutMePage() {
    return (
        <>
            <div className="header">
                <h1>About Me</h1>
            </div>
            <div className='leftcolumn'>
                <div className="card">
                    <div>
                        <p>My Name is Tracy and I became interested in Reiki and holistic therapies over 10 years ago through my late Yoga teacher, Eily Hall.</p>
                    </div>
                    <br></br>
                    <p>Eily taught me the power of healing through Reiki, where she helped me overcome a chronic medical condition. From this and with my love of animals, I embarked on a journey of self discovery in the realm of Reiki and holistic therapies.</p>
                    <br></br><p>I became even more determined to learn and harness the power of Reiki, when my own beloved dog, Esme, became gravelly ill. With the vet informing me to prepare for the worst I put faith in Reiki and myself to successfully heal her. I felt empowered to use this enlightenment to hone my skills so that I could offer Reiki to more animals to help them through troublesome and different times.</p>
                    <br></br><p>Equinine Animal Therapy has been founded to offer Holistic healing using the approach of practicing Reiki through meditating with the animals not doing it to them and more recently the power of Merishia Massage.</p>
                    <br></br><p>I have created this practice so I may help others who feel the same way as I do about their animals.</p>
                </div>
                <div className="card">
                    <h3 className='subheader'>Training and Qualifications</h3>
                    <p>I qualified as an animal led reiki practitioner in May 2020 and as a Usui Ryoho animal reiki practioner in June 2020. Since then I  have been using Reiki to heal and comfort friends and family’s beloved animals. </p>
                    <p>I have experience in working with a wide range of animals and their guardians. I have continued my training and experience in learning Reiki and have qualified to level 2 Usui Reiki and I’m currently studying my Reiki Masters. </p>
                    <p>I am attuned in Usui Reiki to level 2 in both animal and human Reiki and I’m developing practices to incorporate Reiki healing and meditation in helping both animals and their guardians. </p>
                    <p>I now also have over a year’s experience in massaging animals and qualified as a level 4 Merishia massage therapist for dogs and horses in March 2023 </p>
                </div>
            </div>
        </>
    );
}
