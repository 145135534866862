import React from 'react';
import './pages.css';

export default function MerishiaMassage() {
    return (
        <>
        <div className="header">
            <h1>What is Merishia Massage Therapy?</h1>
        </div>
        <div className='leftcolumn'>
            <div classname='card'>
                <p>Merishia massage is a holistic, non-invasive approach that combines soft and deep tissue massage, myofascial release, motion mobilisations and stretching that can help improve your animal’s movement, well-being and encourage relaxation. </p>
                <p>Merishia massage integrates a variety of very effective yet gentle techniques based around Swedish massage and biomechanics which aim to relieve tension, ease muscles, promote recovery after injury or surgery, aid in rehabilitation, complement veterinary treatment and may support in pain relief. </p>
                <p>Massage is a fantastic way to help your four-legged friend as it works naturally with the body and it has long been established through research and observations to give benefit in many ways. </p>
                <p>Each session will be tailored to your horse’s individual needs and guided by their body language. The session will include a variety of techniques from Swedish massage, deep tissue massage, myofascial release, gait assessments, stretching, motion mobilisations, Tellington touch techniques, cranial massage, phototherapy, and Reiki. </p>
                <p> If you’d just like to know more, book a session, or have any questions,please don’t hesitate to get in touch.</p>
            </div>
            <div classname='card'>
                <img
                    src='./images/merisha.jpg'
                    width={200} height={200}
                    alt='' />
                <img 
                    src='./images/dogpaw.jpg'
                    width={200} height={200}
                    alt='' />
            </div>
        </div>
        </>
    );
}
